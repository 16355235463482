<template>
  <v-card class="pa-6 mt-4 elevation-0 featuredArticle">
    <v-row class="align-center justify-center">
      <v-col cols="12">
        <div class="text-h6 align-self-baseline">Featured</div>
      </v-col>
      <v-col class="text-center" cols="12" md="4">
        <img :src="article.image.url" class="featuredArticleImage"/>
      </v-col>
      <v-col cols="12" md="8">
        <h3>{{ article.title }}</h3>
        <p>{{ article.published }}</p>
        <p class="text-body-2">{{ article.content.substring(0, 200) }}...</p>
      </v-col>
      <a :href="article.link" target="_blank" class="text-decoration-none">
        <v-btn class="rounded-pill black white--text text-subtitle-2 px-5">Read More</v-btn>
      </a>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: 'FeaturedArticle',
  components: {},
  props: {
    article: {
      type: Object
    }
  }
}
</script>
<style lang="scss" scoped>
.featuredArticle {
  max-width: 1200px;
}
.featuredArticleImage {
  max-width: 100%;
  border-radius: 15px;
}

</style>