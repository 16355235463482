import { render, staticRenderFns } from "./FeaturedArticle.vue?vue&type=template&id=39aa8e50&scoped=true&"
import script from "./FeaturedArticle.vue?vue&type=script&lang=js&"
export * from "./FeaturedArticle.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedArticle.vue?vue&type=style&index=0&id=39aa8e50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39aa8e50",
  null
  
)

export default component.exports