<template>
  <div class="press">
    <Section class="pressHeader">
      <h1 class="font-weight-bold text-uppercase text-h4 white--text">
        Mathison Projects in the Press.
      </h1>
    </Section>
    <v-container class="d-flex flex-column align-center" style="max-width: 2000px !important;">
      <h2 class="text-center">News</h2>
      <FeaturedArticle :article="featuredArticle"/>
      <hr />
      <div class="d-flex align-start flex-wrap justify-center" style="gap: 1.5rem">
        <v-text-field
          label="Search By Keyword"
          filled
          rounded
          dense
          :value="filters.searchKeyword"
          @change="(e) => onSearchByKeyword(e)"
          style="max-width: 250px"
          :prepend-inner-icon="$vuetify.breakpoint.xs ? 'mdi-magnify' : ''"
        />
        <v-select filled rounded dense :value="filters.publication" @change="(input) => onFilterPublications(input)" style="max-width: 250px" label="Publication" :items="['Forbes', 'Newsweek', 'Rolling Stone', 'Youtube']" />
        <v-select filled rounded dense :value="filters.sort" @change="(input) => setSort(input)" style="max-width: 250px" label="Sort By" :items="['Oldest', 'Newest']" />
        <v-btn class="mt-lg-2" rounded @click="clearFilters()">Clear Filters</v-btn>
      </div>
      <ArticlesList :articles="this.filteredArticles" />
    </v-container>
  </div>
</template>
<script>
import Section from "../components/ui/Section";
import {mapActions, mapGetters} from "vuex";
import FeaturedArticle from "../components/pages/press/FeaturedArticle";
import ArticlesList from "../components/pages/press/ArticlesList";

export default {
  name: "PressPage",
  components: {ArticlesList, FeaturedArticle, Section},
  data() {
    return {
      items: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'Press',
          disabled: true
        }
      ],
      featuredArticle: {},
      filteredArticles: [],
      filters: {
        searchKeyword: '',
        publication: '',
        sort: 'Newest'
      }
    }
  },
  computed: {
    ...mapGetters("articlesStore", {
      articles: "getAllArticles"
    }),
  },
  created() {
    this.getAllArticles()
    this.getFeaturedArticle()
  },
  methods: {
    ...mapActions("articlesStore", ["getArticles"]),
    async getAllArticles() {
      try {
        this.getArticles()
      } catch (err) {
        console.warn(err)
      }
    },
    async getFeaturedArticle() {
      this.featuredArticle = this.articles.filter(article => article.featuredArticle === true)[0]
      this.filteredArticles = [...this.articles];
    },
    async onSearchByKeyword(keyword) {
      if (keyword.length) {
        this.filteredArticles = this.articles.filter(article => article.title.toLowerCase().includes(keyword.toLowerCase()) || article.content.toLowerCase().includes(keyword.toLowerCase()))
        this.filters.searchKeyword = keyword;
        this.filters.publication = '';
        this.filters.sort = 'Newest';
      } else {
        this.filteredArticles = [...this.articles];
        this.filters.searchKeyword = '';
      }
    },
    async onFilterPublications(publication) {
      if (publication === 'Youtube') {
        this.filteredArticles = this.articles.filter(article => !article.publication)
        this.filters.publication = publication;
        this.filters.searchKeyword = '';
      } else {
        let internalPublication = publication.replace(/\s/g, '');
        this.filteredArticles = this.articles.filter(article => article.publication?.toLowerCase() === internalPublication.toLowerCase())
        this.filters.publication = publication;
        this.filters.searchKeyword = '';
      }
    },
    async setSort(e) {
      if (e === 'Oldest') {
        this.filteredArticles = this.filteredArticles.sort(function(a, b) {
          return new Date(a.published) - new Date(b.published)
        })
        this.filters.sort = 'Oldest'
      } else if (e === 'Newest') {
        this.filteredArticles = this.filteredArticles.sort(function (a, b) {
          return new Date(b.published) - new Date(a.published)
        })
        this.filters.sort = 'Newest'
      }
    },
    async clearFilters() {
      this.filteredArticles = this.articles;
      this.filters.searchKeyword = '';
      this.filters.publication = '';
      this.filters.sort = 'Newest';
    }
  },
  mounted() {
    this.filteredArticles = this.articles.sort(function (a, b) {
      return new Date(b.published) - new Date(a.published)
    })
  }
}
</script>
<style lang="scss" scoped>
.pressHeader {
  background-image: url('/img/NewsHeader.webp');
  background-size: cover;
  background-position: top center;
}
</style>