<template>
  <v-container>
    <div class="d-flex justify-space-between flex-wrap px-6 px-md-16" style="gap: 1rem;">
      <v-card v-for="article in paginatedResponse" v-bind:key="article.id" elevation="0" class="d-flex flex-column mb-5 sizingClass"
              style="gap: 0.5rem">
        <v-chip class="align-self-baseline white--text font-weight-bold" :color="getChipColor(article.publication)">{{ article.publication || 'Youtube' }}</v-chip>
        <div v-bind:style="{backgroundImage: `url(${article.image.url})`}" style="width: 100%; height: 250px; border-radius: 25px; background-position: center center; background-size: cover" />
<!--        <v-img :src="article.image.url" style="max-width: 100%" />-->
        <a :href="article.link || article.youtubeLink" target="_blank" style="color: inherit; text-decoration: none"><h5>{{ article.title }}</h5></a>
        <p style="font-size: .8rem">{{ article.published }}</p>
        <vue-markdown style="font-size: 0.75rem">
          {{ article.content.substring(0, 200) }}...
        </vue-markdown>
        <hr />
      </v-card>
    </div>
    <div class="d-flex align-center justify-center" style="gap: 1rem">
      <v-btn icon x-large @click="currentPage--" :disabled="currentPage === 1 || maxPages === 0">
        <v-icon >mdi-chevron-left</v-icon>
      </v-btn>
      <div class="text-h6 font-weight-medium">{{this.currentPage}} of {{maxPages}}</div>
      <v-btn icon x-large @click="currentPage++" :disabled="currentPage === maxPages || maxPages === 0">
        <v-icon >mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import VueMarkdown from "vue-markdown";

export default {
  name: 'ArticlesList',
  components: {VueMarkdown},
  data() {
    return {
      paginatedResponse: [],
      currentPage: 0,
      maxPages: 0
    }
  },
  watch: {
    currentPage() {
      this.setPaginatedResponse(this.articles, 8, this.currentPage)
    },
    articles() {
      this.setPaginatedResponse(this.articles, 8, this.currentPage)
      this.maxPages = Math.round(this.articles.length / 8)
    }
  },
  props: {
    articles: {
      type: Array
    }
  },
  mounted() {
    this.currentPage = 1
    this.maxPages = Math.round(this.articles.length / 8)
    console.log(this.articles)
  },
  methods: {
    setPaginatedResponse(array, pageSize, pageNumber) {
      this.paginatedResponse = array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
    },
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
    goBack() {
      this.currentPage--
      this.scrollToTop()
    },
    goForward() {
      this.currentPage++
      this.scrollToTop()
    },
    getChipColor(publication) {
      if (publication === 'Newsweek') {
        return 'red'
      } else if (publication === 'RollingStone') {
        return 'blue'
      } else if (publication === 'Forbes') {
        return 'green'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.sizingClass {
  @include screen ('md') {
    max-width: 350px;
  }
}
</style>